<template>
  <div class="bar" :style="cssVars"></div>
</template>

<script>
export default {
  name: "ChartBar",
  props: {
    y: Number,
    maxY: Number,
  },
  computed: {
    cssVars() {
      return {
        "--barHeight": (this.y / this.maxY) * 100 + "%",
      };
    },
  },
};
</script>

<style>
.bar {
  flex: 1 1 0px;
  height: var(--barHeight);
  background-color: #000000;
  background-image: url("assets/natural-paper.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}
</style>
