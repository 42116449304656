<template>
  <div class="container">
    <div class="price">{{ this.price }}</div>
  </div>
</template>

<script>
export default {
  name: "KeyCircle",
  props: {
    width: Number,
    color: String,
    price: String,
  },
  computed: {
    cssVars() {
      return {
        "--width": this.width * 2 + "px",
        "--color": this.color,
      };
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.price {
  font-family: "Public Sans", sans-serif;
  font-size: 0.84rem;
}

.circle {
  height: var(--width);
  border-radius: 1000px;
  width: var(--width);
  background-color: var(--color);
}
</style>
