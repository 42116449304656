<template>
  <div id="sidebar">
    <!--  <div class="receipt" id="topreceipt">
      <div id="header">FAST FOOD INDEX</div>
      <div id="motto">
        See cost of living in the United States, solely based on fast food
        costs.
      </div>
    </div> -->
    <div id="header">
      <img src="@/assets/header.png" />
    </div>
    <div id="descriptContainer">
      <div id="descript">
        I found the price of the same product at every single store for 4
        American fast food chains.
        <span style="font-weight: 400"
          >The slight differences in the prices across the country are a solid
          indicator of an area's cost of living.</span
        >
      </div>
    </div>
    <div id="stores">
      <StoreButton
        img="/mcdonalds.jpeg"
        store="McDonald's"
        product="Big Mac"
        color="#C60C0F"
        :active="this.store === 'mcdonalds'"
        @click="setStore('mcdonalds')"
      />
      <StoreButton
        img="/chickfila.png"
        store="Chick-fil-A"
        product="Sandwich"
        color="#DB1932"
        :active="this.store === 'chickfila'"
        @click="setStore('chickfila')"
      />
      <StoreButton
        img="/tacobell.png"
        store="Taco Bell"
        product="Crunchy Taco"
        color="#682A8D"
        :active="this.store === 'tacobell'"
        @click="setStore('tacobell')"
      />
      <StoreButton
        img="/chipotle.jpg"
        store="Chipotle"
        product="Chicken Burrito"
        color="#441400"
        :active="this.store === 'chipotle'"
        @click="setStore('chipotle')"
      />
    </div>
    <div id="traycontainer">
      <div id="tray">
        <div class="food">
          <img :src="this.receiptData[this.store].productPicture" />
        </div>
      </div>
      <div class="receipt" id="billreceipt" ref="receipt">
        <div style="text-align: center">
          <b>{{ this.receiptData[this.store].header }}</b>
          <br />
          {{ this.receiptData[this.store].subheader }}
        </div>
        <br /><br />
        <div
          v-if="this.local?.stores?.[this.store]?.length"
          style="color: darkmagenta"
        >
          <b
            ><i>Stores near {{ this.local.city }}:</i></b
          >

          <br />
          <div v-for="price in this.local.stores[this.store]" :key="price.name">
            {{ price.name
            }}<span style="float: right"
              >${{ Number(price.price).toFixed(2) }}</span
            >
            <br />
          </div>
          <div
            style="
              border-top: 2px dashed darkmagenta;
              width: 100%;
              margin-top: 2px;
              padding-top: 3px;
            "
          >
            <!-- invertPercentile: function(percentile, chain, location){ -->

            {{
              this.invertPercentile(
                this.local.percentile[this.store],
                this.store,
                this.local.city.split(",")[0]
              )
            }}
          </div>

          <br />
        </div>
        <div
          v-else-if="
            this.local &&
            !this.local.error &&
            !this.local?.stores?.[this.store]?.length
          "
          style="color: darkmagenta"
        >
          <b
            ><i>No locations near {{ this.local.city }}</i></b
          ><br /><br />
        </div>

        <i>Least expensive:</i><br />
        <div
          v-for="price in this.receiptData[this.store].cheap"
          :key="price[0]"
        >
          {{ price[0] }}<span style="float: right">{{ price[1] }}</span> <br />
        </div>

        <br />
        <i>Average:</i
        ><span style="float: right">{{
          this.receiptData[this.store].average
        }}</span
        ><br /><br />
        <i>Most expensive:</i><br />
        <div
          v-for="price in this.receiptData[this.store].expensive"
          :key="price[0]"
        >
          {{ price[0] }}<span style="float: right">{{ price[1] }}</span> <br />
        </div>
        <br /><br />
        <div id="chartContainer">
          <ChartBar
            v-for="bar in this.receiptData[this.store].chart"
            :key="bar.x"
            :y="bar.y"
            :maxY="this.receiptData[this.store].chartMaxY"
          />
        </div>
        <div id="labelContainer">
          <div
            class="label"
            v-for="bar in this.receiptData[this.store].chart"
            :key="bar.x"
          >
            ${{ bar.x }}
          </div>
        </div>
        <br /><br />
      </div>
    </div>
    <div id="zipCodeBox">
      <div id="zipExplain">Enter your zip code to see your prices</div>
      <div id="inputBox">
        <input
          ref="input"
          id="zipCode"
          pattern="\d*"
          type="number"
          placeholder="Zip code"
          @keyup.enter="getZipData"
          :value="this.zip"
          @input="(event) => (this.zip = event.target.value)"
        />

        <div
          v-if="this.zip.length == 5 && !this.loading"
          @click="getZipData()"
          class="zipCodeButton"
        >
          ->
        </div>
        <div v-if="this.loading" class="zipCodeButton loading">-></div>
      </div>
    </div>
    <div id="aboutContainer">
      <div id="about">
        I found this data by reverse-engineering each chain's mobile app
        (surprisingly easy, I was never rate-limited). The raw data is available
        to
        <a
          style="font-style: normal; font-weight: 600; color: black"
          href="/data.zip"
          >download here</a
        >. <br /><br />
        <p style="font-size: 1rem">
          By
          <a
            style="font-style: normal; font-weight: 600; color: black"
            href="https://walzr.com"
            >Riley Walz</a
          >. Not affilated with any of the fast food chains above. Data updated
          November 2022.
        </p>
      </div>
    </div>
  </div>
  <div id="mapContainer">
    <div id="map"></div>
    <div id="keyContainer">
      <div id="scale" :style="this.receiptData[this.store].gradient"></div>
      <div id="key">
        <KeyCircle
          v-for="dot in this.receiptData[this.store].key"
          :key="dot.width"
          :width="dot.width"
          :color="dot.color"
          :price="dot.price"
        />
      </div>
    </div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import StoreButton from "./StoreButton.vue";
import ChartBar from "./ChartBar.vue";
import KeyCircle from "./KeyCircle.vue";

export default {
  name: "App",
  components: {
    StoreButton,
    ChartBar,
    KeyCircle,
  },
  mounted() {
    mapboxgl.accessToken =
      "pk.eyJ1Ijoid2FseiIsImEiOiJjazlyYzFxM2EwcmM2M3NydGM5dTZidWkwIn0.SU74SuQeR3O4PuO_KgFVyg";

    this.map = new mapboxgl.Map({
      container: "map",
      cooperativeGestures: this.$isMobile() ? true : false,
      bounds: [-124.68721, 24.4319770250494, -66.96466, 49.38905],
      style: "mapbox://styles/walz/claprj8v6000215mscenu8vyy",
    });
  },
  data() {
    return {
      zip: "", //zip code
      loading: false,
      local: null,
      store: "mcdonalds", // 1 mcd, 2 chick, 3 bell, 4 chipotle
      receiptData: {
        mcdonalds: {
          gradient:
            "background-image: linear-gradient(to right, #fffefc, #fff4d5, #ffeaaf, #ffe187, #ffd75e, #edc546, #dab32d, #c8a105, #a38306, #806706, #5e4c05, #3f3201);",
          key: [
            { width: 4.7, color: "#fecb93", price: "$3.39" },
            { width: 6.6, color: "#fdc24b", price: "$4.49" },
            { width: 8.5, color: "#fccc04", price: "$5.59" },
            { width: 14.3, color: "#bc9803", price: "$6.69" },
            { width: 20, color: "#7c6402", price: "$7.89" },
          ],
          map: "mapbox://styles/walz/claprj8v6000215mscenu8vyy",
          productPicture: "/bigmac.png",
          header: "MCDONALD'S BIG MAC",
          subheader: "13,273 LOCATIONS",
          average: "$5.05",
          expensive: [
            ["Lee, Massachusetts", "$7.89"],
            ["Paramus, New Jersey", "$7.79"],
            ["Fair Lawn, New Jersey", "$7.79"],
          ].reverse(),
          cheap: [
            ["Eufaula, Oklahoma", "$3.39"],
            ["Stigler, Oklahoma", "$3.49"],
            ["Wilburton, Oklahoma", "$3.49"],
          ],
          chartMaxY: 3076,
          chart: [
            {
              x: 3.99,
              y: 447,
            },
            {
              x: 4.29,
              y: 3076,
            },
            {
              x: 4.59,
              y: 2674,
            },
            {
              x: 4.89,
              y: 1878,
            },
            {
              x: 5.19,
              y: 2136,
            },
            {
              x: 5.49,
              y: 1192,
            },
            {
              x: 5.79,
              y: 1170,
            },
            { x: 6.09, y: 451 },
            { x: 6.39, y: 124 },
          ],
        },
        chickfila: {
          key: [
            { width: 4.7, color: "#f8abca", price: "$3.69" },
            { width: 6.6, color: "#f26388", price: "$4.34" },
            { width: 8.5, color: "#ec1c34", price: "$4.99" },
            { width: 14.3, color: "#931221", price: "$5.64" },
            { width: 20, color: "#36070d", price: "$6.29" },
          ],
          gradient:
            "background-image: linear-gradient(to right, #fffcfd, #ffd8e3, #ffb3c3, #ff8c9d, #ff6473, #e94d5b, #d33544, #bd162d, #9b1226, #790f1f, #590c17, #3b080d);",
          productPicture: "/chickfilasandwich.png",
          map: "mapbox://styles/walz/claytbrv6000t15mbkb43ydgj",
          header: "CHICK-FIL-A'S CHICKEN SANDWICH",
          subheader: "2,495 LOCATIONS",
          average: "$4.73",
          expensive: [
            ["New York, New York", "$6.29"],
            ["Washington, D.C.", "$5.99"],
            ["Los Angeles, California", "$5.79"],
          ].reverse(),
          cheap: [
            ["Gainesville, Florida", "$3.69"],
            ["Cumming, Georgia", "$4.19"],
            ["Gastonia, North Carolina", "$4.19"],
          ],
          chartMaxY: 1026,
          chart: [
            {
              x: 4.39,
              y: 1026,
            },
            {
              x: 4.59,
              y: 519,
            },
            {
              x: 4.79,
              y: 518,
            },
            {
              x: 4.99,
              y: 133,
            },
            {
              x: 5.19,
              y: 205,
            },
            {
              x: 5.39,
              y: 50,
            },
            {
              x: 5.59,
              y: 14,
            },
          ],
        },
        tacobell: {
          productPicture: "/tacobelltaco.png",
          map: "mapbox://styles/walz/clayu06q8000p16mzesekkwm0",
          header: "TACO BELL'S CRUNCHY TACO",
          gradient:
            "background-image: linear-gradient(to right, #fdfcfe, #ded2ea, #c1a9d4, #a581be, #8959a8, #754594, #623181, #4f1d6e, #40195a, #321547, #241034, #190822);",
          subheader: "7,414 LOCATIONS",
          average: "$1.75",
          key: [
            { width: 4.7, color: "#bbadd4", price: "$0.99" },
            { width: 6.6, color: "#8b67ae", price: "$1.59" },
            { width: 8.5, color: "#642189", price: "$2.19" },
            { width: 14.3, color: "#3f1556", price: "$2.79" },
            { width: 20, color: "#190822", price: "$3.39" },
          ],
          expensive: [
            ["Lebec, California", "$3.39"],
            ["Berkeley, California", "$3.29"],
            ["New York, New York", "$2.79"],
          ].reverse(),
          cheap: [
            ["Whittier, California", "$0.99"],
            ["Ellisville, Mississippi", "$0.99"],
            ["Rosenberg, Texas", "$1.19"],
          ],
          chartMaxY: 3418,
          chart: [
            {
              x: 1.29,
              y: 156,
            },
            {
              x: 1.39,
              y: 126,
            },
            {
              x: 1.49,
              y: 0,
            },
            {
              x: 1.59,
              y: 3418,
            },
            {
              x: 1.69,
              y: 0,
            },
            {
              x: 1.79,
              y: 3008,
            },
            {
              x: 1.89,
              y: 506,
            },
            {
              x: 1.99,
              y: 26,
            },
            {
              x: 2.09,
              y: 49,
            },
          ],
        },
        chipotle: {
          key: [
            { width: 4.7, color: "#eaa4b8", price: "$7.85" },
            { width: 6.6, color: "#d75869", price: "$8.66" },
            { width: 8.5, color: "#c40c0b", price: "$9.48" },
            { width: 14.3, color: "#7a0807", price: "$10.29" },
            { width: 20, color: "#310303", price: "$11.10" },
          ],
          productPicture: "/chipotleburrito.png",
          map: "mapbox://styles/walz/claytjzcm000c14mt7hori01a",
          gradient:
            "background-image: linear-gradient(to right, #fffcfd, #fed3dd, #fbaab7, #f3808a, #e55658, #ce3f40, #b7262a, #a00113, #830014, #670112, #4b030d, #310303);",
          header: "CHIPOTLE'S CHICKEN BURRITO",
          subheader: "3,083 LOCATIONS",
          average: "$8.98",
          expensive: [
            ["New York, New York", "$11.10"],
            ["San Francisco, California", "$10.60"],
            ["Ronkonkoma, New York", "$10.35"],
          ].reverse(),
          cheap: [
            ["Iowa City, Iowa", "$7.85"],
            ["Athens, Ohio", "$7.85"],
            ["Ann Arbor, Michigan", "$7.85"],
          ],
          chartMaxY: 855,
          chart: [
            {
              x: 7.85,
              y: 95,
            },
            {
              x: 8.15,
              y: 685,
            },
            {
              x: 8.45,
              y: 383,
            },
            {
              x: 8.75,
              y: 487,
            },
            {
              x: 9.05,
              y: 855,
            },
            {
              x: 9.35,
              y: 239,
            },
            {
              x: 9.65,
              y: 162,
            },
            {
              x: 9.95,
              y: 31,
            },
          ],
        },
      },
    };
  },
  methods: {
    invertPercentile: function (percentile, chain, location) {
      let chains = {
        chipotle: "burrito",
        chickfila: "chicken sandwich",
        mcdonalds: "big mac",
        tacobell: "taco",
      };
      if (percentile >= 50) {
        return `Buying a ${
          chains[chain]
        } in ${location} is more expensive than ${percentile.toFixed(
          0
        )}% of the country.`;
      } else {
        return `Buying a ${chains[chain]} in ${location} is cheaper than ${(
          100 - percentile
        ).toFixed(0)}% of the country.`;
      }
    },
    getZipData: function () {
      this.loading = true;
      let that = this;
      fetch("https://fastfoodindex.walzr.com/api/" + this.zip)
        .then((x) => x.json())
        .then((data) => {
          that.loading = false;

          if (data.error) return alert(data.error);
          that.local = data;
          console.log("TTTT", that.$refs);
          that.$refs.input.blur();
          that.$refs["receipt"].scrollIntoView({ behavior: "smooth" });

          console.log(that.local);
        });
    },
    setStore: function (storeNumber) {
      this.store = storeNumber;
      this.map.setStyle(this.receiptData[this.store].map);
    },
  },
};
</script>

<style>
@media only screen and (max-width: 400px) {
  #header img {
    max-width: 308px !important;
  }
  html {
    font-size: 13px !important;
  }
  .button {
    height: 38px !important;
  }

  #stores > * + * {
    margin-left: 10px !important;
  }

  #billreceipt {
    width: 245px !important;
    margin-top: -50px !important;
    min-width: auto !important;
  }

  #tray {
    align-items: baseline !important;
  }
  .food {
    width: 145px !important;
    margin-right: 80px !important;
    margin-top: 56px !important;
  }

  #tray {
    height: auto !important;
  }
}

@media only screen and (max-width: 1000px) {
  #app {
    flex-direction: column;
  }

  #sidebar {
    height: auto !important;
  }

  #billreceipt {
    margin-top: -427px;
    margin-left: 0px !important;
  }

  #sidebar {
    width: 100% !important;
    overflow-y: initial !important;
  }

  #mapContainer {
    width: 100% !important;
  }

  #map {
    height: 90vh !important;
  }

  #traycontainer {
    flex-direction: column;
    margin-top: 10px !important;
    align-items: center !important;
  }

  #tray {
    background-position: top !important;
    margin-top: 19px !important;
    margin-left: -56px !important;
  }

  body,
  html {
    overflow-y: initial !important;
  }
}

/*zipCodeBox">
        <div id="zipExplain">
          Enter your zip code to see your fast food prices.
        </div>
        <div id="inputBox">
          <input id="zipCode"*/

#zipCodeBox {
  display: flex;
  justify-content: space-around;
  max-width: 500px;
  padding: 0 15px;
  margin: 30px auto;
  margin-top: 48px;
}

#zipCodeBox > * + * {
  margin-left: 4px;
}

#inputBox {
  /*width: 100px;*/
}

#zipCode {
  border: none;
  font-family: Merchant, Courier;
  font-size: 3rem;
  width: 9rem;
  background-image: url(assets/paper.jpg);
  background-position: center;
  filter: brightness(110%);
  padding: 10px;
  border-radius: 4px;
}

#inputBox {
  position: relative;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.loading {
  animation: blinker 1s linear infinite;
}

.zipCodeButton {
  position: absolute;
  right: 5px;
  font-family: Merchant, Courier;
  border-radius: 500px;
  height: 2rem;
  background-color: #87ceeb;
  line-height: 2rem;
  font-size: 1.8rem;
  top: calc(50% - 1rem);
  width: 2rem;
  cursor: pointer;
  text-align: center;
}

#zipExplain {
  font-family: "Public Sans", sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
  max-width: 282px;
  color: #333;
  text-transform: uppercase;
}

#mapContainer {
  position: relative;
  width: 50%;
  height: 100%;
}

#scale {
  width: 100%;
  height: 25px;
  border-radius: 3px;
}

#keyContainer {
  position: absolute;
  top: 10px;
  left: 0;
  border-radius: 8px;
  right: 0;
  margin: auto;
  width: fit-content;
  background: #ffffff90;
  padding: 8px;
}

#key {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
}

#key > * + * {
  margin-left: 18px;
}

#chartContainer {
  height: 75px;
  display: flex;
  align-items: flex-end;
}
#labelContainer {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.label {
  flex: 1 1 0px;
  font-size: 1.3rem;
  width: 1px;
  transform: rotate(45deg) translateY(8px) translateX(19px);
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -0.0001em !important;
}

#stores {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

#stores > * + * {
  margin-left: 15px;
}

#app {
  display: flex;
  width: 100%;
  height: 100%;
}

#map {
  width: 100%;
  height: 100%;
}

#sidebar {
  width: 50%;
  height: 100%;
  overflow-y: scroll;
  background-color: #fabc2a;
  background-image: url("assets/white-wall.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

.receipt {
  background-image: url(assets/paper.jpg);
  background-position: center;
  filter: brightness(110%);
  background-color: #fff;
  padding: 20px;
  text-transform: uppercase;
  font-family: merchant, Courier;
  /*height: 250px;*/
  font-size: 1.6rem;
}

#topreceipt {
  width: 340px;
  transform: rotate(-4deg) translateY(-130px);
  max-height: 400px;
  padding-top: 200px;
}

#billreceipt {
  width: 340px;
  min-width: 340px;
  margin-left: -50%;
  margin-right: 39px;
  transform: rotate(3deg);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  padding-bottom: 47px;
  /*padding-top: 200px;*/
}

#descriptContainer {
  background-color: #fff;
  margin: -10px 0 30px 0;
}

#descript {
  max-width: 500px;
  padding: 30px 10px;
  font-weight: 600;
  margin: 0 auto;
  color: #333;
  font-size: 1.2rem;
  font-family: "Public Sans", sans-serif;
}

#aboutContainer {
  background-color: #fff;
  margin: 0px 0 0px 0;
}

#about {
  max-width: 500px;
  padding: 30px 10px;
  margin: 0 auto;
  color: #333;
  font-size: 1.2rem;
  font-family: "Public Sans", sans-serif;
}

#motto {
  font-size: 1.9rem;
  padding-bottom: 10px;
  /*border-bottom: 2px dashed black;*/
}

#header {
  font-size: 2.4rem;
  font-weight: 600;
  text-align: center;
  background-size: cover;
  overflow: hidden;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 80);
  background-image: url(assets/sky.jpg);
}

#header img {
  max-width: 400px;
  margin-bottom: -10px;
  margin-top: 25px;
}

#traycontainer {
  width: 100%;
  position: relative;
  justify-content: space-between;
  display: flex;
  margin-top: 40px;
}

#tray {
  background-image: url(assets/tray.png);
  height: 500px;
  max-width: 500px;
  width: calc(100% - 50px);
  background-position: center;
  /*padding: 0 30px;*/
  margin-left: 50px;

  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.food {
  max-width: 300px;
  margin-right: 80px;
  /*margin-top: 60px;*/
  transform: rotate(-40deg);
  /*position: absolute;*/
  /*top: 50%;*/
  /*left: 50%;*/
}

.food img {
  width: 100%;
}
</style>
